import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";

import "./map.css";

import MapStyles from "./mapStyles";
import Loader from "../../Helper/Loaders/Loader";
// import MapLoader from "../../Helper/Loaders/Loader";
import {
  SetMapFullScreen,
  SetMapSearch,
  SetMapSearchedBattery,
  SetMapClusterClick,
} from "../../Actions/Map";
import { useDispatch, useSelector } from "react-redux";
import FullscreenRoundedIcon from "@mui/icons-material/FullscreenRounded";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Close from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import BatteryStdIcon from "@mui/icons-material/BatteryStd";
import FactoryIcon from "@mui/icons-material/Factory";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import ReplayIcon from "@mui/icons-material/Replay";
import FindMapIcon from "./FindMapIcon";
import MapInfoWindow from "./MapInfoWindow";
import CODE from "../../Static/Constants/StatusCodes";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Tooltip } from "antd";
import NoDataMap from "../../Helper/Loaders/NoDataMap";
import Events from "../../Analytics/Events";
import { getPartnerOEM } from "../../Api/MapApi";
import { SetSessionExpired } from "../../Actions";
import OEMImage from "../../Assets/Icons/Map/OEM.svg";
import Partner from "../../Assets/Icons/Map/Partner1.svg";
import MoonLoader from "react-spinners/MoonLoader";
import Cookies from "universal-cookie";

// 12.9716° N, 77.5946° E

// const center = { lat: 12.9716, lng: 77.5946 };

// const containerStyleBefore = {
//   width: "100%",
//   height: "100%",
// };

// const containerStyleAfter = {
//   width: "100%",
//   height: "100%",
//   filter: "blur(1px)",
// };

var clusterStyles = [
  {
    textColor: "white",
    url: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/cluster11.png",
    height: 35,
    width: 35,
  },
  {
    textColor: "white",
    url: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/cluster12.png",
    height: 40,
    width: 40,
  },
  {
    textColor: "white",
    url: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/cluster13.png",
    height: 45,
    width: 45,
  },
  {
    textColor: "white",
    url: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/cluster14.png",
    height: 50,
    width: 50,
  },
];

var clusterStyles2 = [
  {
    textColor: "black",
    url: "https://bounceicons.s3.ap-south-1.amazonaws.com/BounceIcons/Partner1.svg",
    height: 35,
    width: 35,
    anchorText: [-3, 0],
  },
  {
    textColor: "black",
    url: "https://bounceicons.s3.ap-south-1.amazonaws.com/BounceIcons/Partner2.svg",
    height: 40,
    width: 40,
    anchorText: [-3, 0],
  },
  {
    textColor: "black",
    url: "https://bounceicons.s3.ap-south-1.amazonaws.com/BounceIcons/Partner3.svg",
    height: 45,
    width: 45,
    anchorText: [-3, 0],
  },
  {
    textColor: "black",
    url: "https://bounceicons.s3.ap-south-1.amazonaws.com/BounceIcons/Partner4.svg",
    height: 50,
    width: 50,
    anchorText: [-3, 0],
  },
];

var clusterStyles3 = [
  {
    textColor: "black",
    url: "https://bounceicons.s3.ap-south-1.amazonaws.com/BOP_Icons/Square_pink.svg",
    height: 35,
    width: 35,
    anchorText: [0, 0],
  },
];

// const sideDivStyle = {
//   position: "absolute",
//   right: "5rem",
//   top: "-9px",
//   transition: "all 0.5s ease",
// };
// const sideDivStyle2 = {
//   position: "relative",
//   width: "25%",
// };

const fullScreenStyle = {
  width: "100%",
  height: "calc(100vh - 6.5rem)",
  position: "absolute",
  top: "6.5rem",
  left: "0px",
  zIndex: 10000,

  // zIndex:9999
};

const severity = [
  {
    name: "<30%",
    color: ThemeProperties.map_soc_low,
  },
  {
    name: "30%~50%",
    color: ThemeProperties.map_soc_medium,
  },
  {
    name: "50%>",
    color: ThemeProperties.map_soc_high,
  }
];

const options_cluster = {
  gridSize: 40,
  styles: clusterStyles,
};

const options_cluster_2 = {
  gridSize: 40,
  styles: clusterStyles2,
};

const options_cluster_3 = {
  gridSize: 40,
  styles: clusterStyles3,
};

function Map(props) {
  const [map, setMap] = React.useState(null);
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const fleetID = +cookies.get("fleetID");
  const orgName = cookies.get("orgName");

  const currentZoom = 3; //default
  const fullScreenMode = useSelector((state) => state.MapFullScreen.value);
  const mapSearch = useSelector((state) => state.MapSearch.value);
  const mapSearchedBattery = useSelector(
    (state) => state.MapSearchedBattery.value
  );
  const mapFoundBatteries = useSelector(
    (state) => state.MapFoundBatteries.value
  );
  const mapClusterClick = useSelector((state) => state.MapClusterClick.value);

  const clustererRef = useRef();
  const clustererRef2 = useRef();
  const clustererRef3 = useRef();

  const [partner, setPartner] = useState(false);
  const [oem, setOEM] = useState(false);
  const [battery, setBattery] = useState(true);

  const [partnerData, setPartnerData] = useState({
    data: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });

  const [oemData, setOEMData] = useState({
    data: [],
    responseStatus: { code: CODE.LOADING, msg: "" },
  });

  useEffect(() => {
    clustererRef2.current?.repaint();
    clustererRef3.current?.repaint();
    clustererRef.current?.repaint();
    callBound1(props.data.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, mapSearchedBattery]);

  useEffect(() => {
    clustererRef2.current?.repaint();
    clustererRef3.current?.repaint();
    if (oem) {
      getPartnerOEM("oem").then((res) => {
        if (res.responseStatus.code === CODE.SUCCESS) {
          setOEMData({
            data: res.response.data,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          setOEMData({
            data: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
    }
    if (!oem) {
      setOEMData({
        data: [],
        responseStatus: {
          code: CODE.LOADING,
          msg: "",
        },
      });
    }
    if (partner) {
      getPartnerOEM("partner").then((res) => {
        if (res.responseStatus.code === CODE.SUCCESS) {
          setPartnerData({
            data: res.response.data,
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        } else {
          if (res.responseStatus.code === -2) dispatch(SetSessionExpired(true));
          setPartnerData({
            data: [],
            responseStatus: {
              code: res.responseStatus.code,
              msg: res.responseStatus.msg,
            },
          });
        }
      });
    }
    if (!partner) {
      setPartnerData({
        data: [],
        responseStatus: {
          code: CODE.LOADING,
          msg: "",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oem, partner]);

  const ref = useRef();
  const ref1 = useRef();
  const searchBattery = useRef();
  const selectPartner = useRef();
  const selectOEM = useRef();
  const selectBattery = useRef();
  const resetCluster = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC0HcA0iGnP_qh2E1AsKCG7mbrVL3uW2pY",
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLoad = React.useCallback(function callback(map) {
    var mybounds = new window.google.maps.LatLngBounds();

    for (var i = 0; i < props.data.data.length; i++) {
      mybounds.extend(
        new window.google.maps.LatLng(
          props.data.data[i]["latitude"],
          props.data.data[i]["longitude"]
        )
      );
    }
    map.fitBounds(mybounds);
    setMap(map);

    if (map && ref) {
      map.controls[window.google.maps.ControlPosition["TOP_RIGHT"]].push(
        ref.current
      );
    }
    if (map && ref1) {
      map.controls[window.google.maps.ControlPosition["TOP_RIGHT"]].push(
        ref1.current
      );
    }
    if (map && searchBattery) {
      map.controls[window.google.maps.ControlPosition["TOP_LEFT"]].push(
        searchBattery.current
      );
    }
    if (map && selectPartner) {
      map.controls[window.google.maps.ControlPosition["TOP_RIGHT"]].push(
        selectPartner.current
      );
    }
    if (map && selectOEM) {
      map.controls[window.google.maps.ControlPosition["TOP_RIGHT"]].push(
        selectOEM.current
      );
    }
    if (map && selectBattery) {
      map.controls[window.google.maps.ControlPosition["TOP_RIGHT"]].push(
        selectBattery.current
      );
    }
    if (map && resetCluster) {
      map.controls[window.google.maps.ControlPosition["TOP_LEFT"]].push(
        resetCluster.current
      );
    }
  });

  function callBound1(locations) {
    if (map !== null) {
      if (mapSearchedBattery !== "") {
        if (!props.clickedMapBattery) {
          const batteryID = locations.find(
            (item) => item.batteryID === mapSearchedBattery
          );

          var index = locations.findIndex(
            (item) => item.batteryID === mapSearchedBattery
          );

          props.setOpenInfoWindowMarkerId(index);

          var mybounds = new window.google.maps.LatLngBounds();

          mybounds.extend(
            new window.google.maps.LatLng(
              batteryID["latitude"],
              batteryID["longitude"]
            )
          );

          map.fitBounds(mybounds);
          map.setZoom(currentZoom);

          setMap(map);
        }
        // setCurrentZoom(16)
      } else {
        if (locations.length !== 0) {
          setTimeout(function () {
            var mybounds = new window.google.maps.LatLngBounds();
            for (var i = 0; i < locations.length; i++) {
              mybounds.extend(
                new window.google.maps.LatLng(
                  locations[i]["latitude"],
                  locations[i]["longitude"]
                )
              );
            }
            map.fitBounds(mybounds);
            ((fleetID === 1 || fleetID === 3 ) && map.setZoom(15))
            map.setZoom(currentZoom);
            setMap(map);
          }, 400);
        } else {
          //
        }
      }
      // setCurrentZoom(5)
    }
  }

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleFullScreenOpen = () => {
    dispatch(SetMapFullScreen(!fullScreenMode));
    Events("clicked Map fullscreen icon");
  };

  const handlePartnerClick = () => {
    setPartner((prev) => !prev);
  };

  const handleOEMClick = () => {
    setOEM((prev) => !prev);
  };

  const handleBatteryClick = () => {
    setBattery((prev) => !prev);
  };

  const handleSearchClick = () => {
    dispatch(SetMapSearch(!mapSearch));
    // dispatch(SetMapSearchedBattery(""));
    Events("clicked Map expand search batteries icon");
  };

  const handleToggleOpen = (markerid) => {
    props.setOpenInfoWindowMarkerId(markerid);
  };
  const handleToggleClose = () => {
    props.setOpenInfoWindowMarkerId("");
  };

  const handleToggleOpenPartner = (markerid) => {
    props.setOpenPartnerMarker(markerid);
  };
  const handleToggleClosePartner = () => {
    props.setOpenPartnerMarker("");
  };

  const handleToggleOpenOEM = (markerid) => {
    props.setOpenOEMMarker(markerid);
  };
  const handleToggleCloseOEM = () => {
    props.setOpenOEMMarker("");
  };

  return isLoaded ? (
    <div style={{ height: "100%" }}>
      <div className={fullScreenMode ? "notMainDiv" : "mainDiv"}>
        <div className="mapDiv">
          {props.data.responseStatus.code === CODE.LOADING && (
            <>
              <div
                className="loaderDiv"
                style={{
                  height: fullScreenMode ? "90vh" : "35vh",
                  zIndex: fullScreenMode ? 10001 : 1,
                  display:"inline"
                }}
              >
                <MoonLoader
                  color={ThemeProperties.purple}
                  size={40}
                  speedMultiplier={1}
                />
                
                <Typography
                  style={{
                    color: "#B2AFC3",
                    marginTop: "10px",
                    fontSize: "14px",
                  }}
                >
                  Loading
                </Typography>
              </div>
            </>
          )}

          {props.data.responseStatus.code === CODE.NODATA && (
            <>
              <div
                className="loaderDiv"
                style={{
                  height: fullScreenMode ? "90vh" : "35vh",
                  zIndex: fullScreenMode ? 10001 : 1,
                }}
              >
                <NoDataMap/>
              </div>
            </>
          )}

          <GoogleMap
            mapContainerStyle={
              fullScreenMode === true
                ? fullScreenStyle
                : { width: "100%", height: "100%", position: "relative" }
            }
            // center={center}
            mapContainerClassName="mapContainer"
            zoom={currentZoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              fullscreenControl: false,
              styles: MapStyles,
              minZoom: 2,
              maxZoom: 22,
            }}
          >
            {/* {oem && (
              <MarkerClusterer
                minimumClusterSize={2}
                maxZoom={20}
                onLoad={(clusterer) => (clustererRef3.current = clusterer)}
                options={options_cluster_3}
              >
                {(clusterer) => {
                  return oemData.data.map((location, index) => (
                    <>
                      <Marker
                        key={index}
                        position={{
                          lat: location["latitude"],
                          lng: location["longitude"],
                        }}
                        clusterer={clusterer}
                        // noClustererRedraw={true}
                        // onClick={(e) => handleClick(location)}
                        icon={OEMImage}
                        onClick={() => {
                          handleToggleOpenOEM(index);
                          Events("Map oem marker id");
                        }}
                      >
                        {props.openOEMMarker === index && (
                          <InfoWindow
                            position={{
                              lat: location["latitude"],
                              lng: location["longitude"],
                            }}
                          >
                            <div style={{ width: "230px", padding: "15px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography variant="tableHead">
                                    OEM &nbsp;- &nbsp;
                                  </Typography>
                                  <Typography variant="textSubtitleSmall">
                                    {location["partnerID"]}
                                  </Typography>
                                </div>

                                <IconButton
                                  fontSize="small"
                                  onClick={() => handleToggleCloseOEM()}
                                >
                                  <Close style={{ fontSize: "15px" }} />
                                </IconButton>
                              </div>

                              <Box sx={{ mt: 1 }}></Box>

                              <hr
                                style={{ borderTop: "1px solid #ececec" }}
                              ></hr>
                              <Box sx={{ mt: 2 }}></Box>

                              <Grid container spacing={0} sx={{ mb: 1 }}>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                  <Typography variant="tableHead">
                                    Lat/Long
                                  </Typography>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                  <Typography variant="textSubtitleSmall">
                                    {location["latitude"]?.toFixed(6) +
                                      "/" +
                                      location["longitude"]?.toFixed(6)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                    </>
                  ));
                }}
              </MarkerClusterer>
            )}
            {partner && (
              <MarkerClusterer
                minimumClusterSize={2}
                maxZoom={20}
                onLoad={(clusterer) => (clustererRef2.current = clusterer)}
                options={options_cluster_2}
              >
                {(clusterer) => {
                  return partnerData.data.map((location, index) => (
                    <>
                      <Marker
                        key={index}
                        position={{
                          lat: location["latitude"],
                          lng: location["longitude"],
                        }}
                        clusterer={clusterer}
                        // noClustererRedraw={true}
                        // onClick={(e) => handleClick(location)}
                        icon={Partner}
                        onClick={() => {
                          handleToggleOpenPartner(index);
                          Events("Map occupant marker id");
                        }}
                      >
                        {props.openPartnerMarker === index && (
                          <InfoWindow
                            position={{
                              lat: location["latitude"],
                              lng: location["longitude"],
                            }}
                          >
                            <div style={{ width: "230px", padding: "15px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography variant="tableHead">
                                    Occupant ID &nbsp;- &nbsp;
                                  </Typography>
                                  <Typography variant="textSubtitleSmall">
                                    {location["partnerID"]}
                                  </Typography>
                                </div>

                                <IconButton
                                  fontSize="small"
                                  onClick={() => handleToggleClosePartner()}
                                >
                                  <Close style={{ fontSize: "15px" }} />
                                </IconButton>
                              </div>

                              <Box sx={{ mt: 1 }}></Box>

                              <hr
                                style={{ borderTop: "1px solid #ececec" }}
                              ></hr>
                              <Box sx={{ mt: 2 }}></Box>

                              <Grid container spacing={0} sx={{ mb: 1 }}>
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                  <Typography variant="tableHead">
                                    Lat/Long
                                  </Typography>
                                </Grid>
                                <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                                  <Typography variant="textSubtitleSmall">
                                    {location["latitude"]?.toFixed(6) +
                                      "/" +
                                      location["longitude"]?.toFixed(6)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </div>
                          </InfoWindow>
                        )}
                      </Marker>
                    </>
                  ));
                }}
              </MarkerClusterer>
            )} */}

            {battery && (
              <MarkerClusterer
                minimumClusterSize={2}
                maxZoom={20}
                onLoad={(clusterer) => (clustererRef.current = clusterer)}
                options={options_cluster}
                onClick={() => {
                  Events("Map marker cluster clicked");
                  dispatch(SetMapClusterClick(true));
                }}
              >
                {(clusterer) => {
                  return props.data.data.map((location, index) => (
                    <>
                      <Marker
                        key={index}
                        position={{
                          lat: location["latitude"],
                          lng: location["longitude"],
                        }}
                        clusterer={clusterer}
                        noClustererRedraw={true}
                        onClick={() => {
                          props.setOpenInfoWindowMarkerId("");
                          handleToggleOpen(index);
                          Events("clicked Map InfoWindow");
                          // setClickedMapBattery(true);
                          props.setClickedMapBattery(true);
                          dispatch(
                            SetMapSearchedBattery(location["batteryID"])
                          );
                          dispatch(SetMapClusterClick(true));
                        }}
                        icon={FindMapIcon(orgName, location.bmsAlertCount,location.soc)}
                      >
                        {props.openInfoWindowMarkerId === index && (
                          <InfoWindow
                            position={{
                              lat: location["latitude"],
                              lng: location["longitude"],
                            }}
                            style={{ padding: "10px" }}
                          >
                            <MapInfoWindow
                              searchType={"Device"}
                              batteryID={location["batteryID"]}
                              deviceID={location["deviceID"]}
                              textToShow={location["assetID"]}
                              handleToggleClose={handleToggleClose}
                              lat={location["latitude"]}
                              lng={location["longitude"]}
                            />
                          </InfoWindow>
                        )}
                      </Marker>
                    </>
                  ));
                }}
              </MarkerClusterer>
            )}

            <div style={{ padding: "1rem" }}>
              <Tooltip
                placement={"left"}
                zIndex={10001}
                title={
                  fullScreenMode ? "Close full screen" : "Show full screen"
                }
              >
                <FullscreenRoundedIcon
                  ref={ref}
                  onClick={handleFullScreenOpen}
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    right: 0,
                    border: `1px solid ${ThemeProperties.purple}`,
                    backgroundColor: fullScreenMode
                      ? ThemeProperties.purple
                      : "white",
                    padding: "5px",
                    borderRadius: "50%",
                    margin: "14px 5px 5px",
                    color: fullScreenMode
                      ? ThemeProperties.white
                      : ThemeProperties.purple,
                  }}
                ></FullscreenRoundedIcon>
              </Tooltip>
              {/* <Tooltip
                placement={"left"}
                zIndex={10001}
                title={partner ? "Hide occupants" : "Show occupants"}
              >
                <PersonIcon
                  ref={selectPartner}
                  onClick={() => handlePartnerClick()}
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    right: 0,
                    border: `1px solid ${ThemeProperties.purple}`,
                    backgroundColor: partner ? ThemeProperties.purple : "white",
                    padding: "5px",
                    borderRadius: "50%",
                    margin: "60px 5px 5px",
                    color: partner
                      ? ThemeProperties.white
                      : ThemeProperties.purple,
                    opacity: !battery && !oem ? 0.8 : 1,
                    pointerEvents: !battery && !oem ? "none" : "auto",
                  }}
                ></PersonIcon>
              </Tooltip> */}

              {/* <Tooltip
                title={oem ? "Hide OEM" : "Show OEM"}
                placement={"left"}
                zIndex={10001}
              >
                <FactoryIcon
                  ref={selectOEM}
                  onClick={() => handleOEMClick()}
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    right: 0,
                    border: `1px solid ${ThemeProperties.purple}`,
                    backgroundColor: oem ? ThemeProperties.purple : "white",
                    padding: "5px",
                    borderRadius: "50%",
                    margin: "104px 5px 5px",
                    color: oem ? ThemeProperties.white : ThemeProperties.purple,
                    opacity: !partner && !battery ? 0.8 : 1,
                    pointerEvents: !partner && !battery ? "none" : "auto",
                  }}
                ></FactoryIcon>
              </Tooltip> */}

              {/* <Tooltip
                title={battery ? "Hide Batteries" : "Show Batteries"}
                placement={"left"}
                zIndex={10001}
              >
                <BatteryStdIcon
                  ref={selectBattery}
                  onClick={() => handleBatteryClick()}
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    right: 0,
                    border: `1px solid ${ThemeProperties.purple}`,
                    backgroundColor: battery ? ThemeProperties.purple : "white",
                    padding: "5px",
                    borderRadius: "50%",
                    margin: "147px 5px 5px",
                    color: battery
                      ? ThemeProperties.white
                      : ThemeProperties.purple,
                    opacity: !partner && !oem ? 0.8 : 1,
                    pointerEvents: !partner && !oem ? "none" : "auto",
                  }}
                ></BatteryStdIcon>
              </Tooltip> */}
              <Box
                ref={ref1}
                style={{
                  display:"flex",
                  right: 45,
                  // backgroundColor: "white",
                  // padding: "5px",
                  // borderRadius: "4px",
                  // margin: "15px 5px 5px",
                  // boxShadow: "0px 2px 6px 2px rgba(76, 83, 96, 0.18), 0px 1px 2px 0px rgba(76, 83, 96, 0.30)"
                }}
              >
                <Box style={{display:"flex",backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "4px",
                  margin: "15px 5px 5px",
                  boxShadow: "0px 2px 6px 2px rgba(76, 83, 96, 0.18), 0px 1px 2px 0px rgba(76, 83, 96, 0.30)"}}>
                <Typography variant="mapLegend">SOC:&nbsp;&nbsp; </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {severity.map((item) => (
                    <>
                      <CircleRoundedIcon sx={{ color: item.color, mr: 0.5 }} />
                      <Typography variant="mapLegend">{item.name}&nbsp;&nbsp;</Typography>
                    </>
                  ))}
                </Box>
                </Box>
                {/* <Box style={{display:"flex",backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "4px",
                  margin: "15px 5px 5px",
                  boxShadow: "0px 2px 6px 2px rgba(76, 83, 96, 0.18), 0px 1px 2px 0px rgba(76, 83, 96, 0.30)"}}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>

                    
                      <CircleRoundedIcon sx={{ color: ThemeProperties.map_bms_error, mr: 0.5 }} />
                      <Typography variant="mapLegend">{"BMS Errors"}&nbsp;&nbsp;</Typography>
                    </Box>
                  </Box> */}
              </Box>
              {/* <Tooltip
                title={
                  mapFoundBatteries.length > 0
                    ? "Open searched batteries"
                    : "Search batteries to open"
                }
                placement={"bottom"}
                zIndex={10001}
              >
                <KeyboardDoubleArrowRightIcon
                  ref={searchBattery}
                  onClick={mapFoundBatteries.length > 0 && handleSearchClick}
                  style={{
                    fontSize: "20px",
                    cursor:
                      mapFoundBatteries.length > 0 ? "pointer" : "default",
                    left: 20,
                    border: `1px solid ${
                      mapFoundBatteries.length > 0
                        ? ThemeProperties.purple
                        : ThemeProperties.dark_grey
                    }`,
                    backgroundColor:
                      mapFoundBatteries.length > 0
                        ? ThemeProperties.purple
                        : "white",
                    padding: "5px",
                    borderRadius: "50%",
                    margin: "14px 5px 5px 0px",
                    color:
                      mapFoundBatteries.length > 0
                        ? ThemeProperties.white
                        : ThemeProperties.dark_grey,
                    //pointerEvents: mapFoundBatteries.length > 0 ? "auto" : "none",
                    opacity: mapFoundBatteries.length > 0 ? 1 : 0.8,
                  }}
                ></KeyboardDoubleArrowRightIcon>
              </Tooltip> */}
              <Tooltip
                title={
                  mapClusterClick
                    ? "Reset the map"
                    : "Reset the map"
                }
                placement={"bottom"}
                zIndex={10001}
              >
                <ReplayIcon
                  ref={resetCluster}
                  onClick={() => {
                    callBound1(props.data.data);
                    dispatch(SetMapSearchedBattery(""));
                    dispatch(SetMapClusterClick(false));
                    props.setOpenInfoWindowMarkerId("");
                  }}
                  sx={{
                    fontSize: "20px",
                    cursor: mapClusterClick ? "pointer" : "default",
                    left: { xs: 225, sm: 225, md: 225, lg: 225, xl: 245 },
                    border: `1px solid ${
                      mapClusterClick
                        ? ThemeProperties.purple
                        : ThemeProperties.dark_grey
                    }`,
                    backgroundColor: mapClusterClick
                      ? ThemeProperties.purple
                      : "white",
                    padding: "5px",
                    borderRadius: "50%",
                    margin: "14px 5px 5px",
                    color: mapClusterClick
                      ? ThemeProperties.white
                      : ThemeProperties.dark_grey,
                    //pointerEvents: mapClusterClick ? "auto" : "none",
                    opacity: mapClusterClick ? 1 : 0.8,
                  }}
                ></ReplayIcon>
              </Tooltip> 
            </div>
          </GoogleMap>

        </div>
      </div>
    </div>
  ) : (
    <div style={{ height: "100vh" }}>
      <Loader />
    </div>
  );
}

export default React.memo(Map);
