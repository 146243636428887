import SOC_Low from "../../Assets/Icons/Map/Icons/Tower_low_soc.png";
import SOC_Medium from "../../Assets/Icons/Map/Icons/Tower_medium_soc.png";
import SOC_High from "../../Assets/Icons/Map/Icons/Tower_high_soc.png";
import TowerAlert from "../../Assets/Icons/Map/Icons/Tower_bms_error.png";

import BNextSOC_Low from "../../Assets/Icons/Map/Icons/bnext_tower_low_soc.png";
import BNextSOC_Medium from "../../Assets/Icons/Map/Icons/bnext_tower_medium_soc.png";
import BNextSOC_High from "../../Assets/Icons/Map/Icons/b_next_tower_high_soc.png";
import BNextTowerAlert from "../../Assets/Icons/Map/Icons/bnext_tower_bms_error.png";


const orgIcons = {
  default: {
    low: SOC_Low,
    medium: SOC_Medium,
    high: SOC_High,
    alert: TowerAlert,
  },
  bnext: {
    low: BNextSOC_Low,
    medium: BNextSOC_Medium,
    high: BNextSOC_High,
    alert: BNextTowerAlert,
  },
};

function getSOCIcon(orgName, soc) {
  if (soc < 30) return orgIcons[orgName]?.low || orgIcons.default.low;
  if (soc < 50) return orgIcons[orgName]?.medium || orgIcons.default.medium;
  return orgIcons[orgName]?.high || orgIcons.default.high;
}

function FindMapIcon(orgName, alert, soc) {
  let normalizedOrg = "default"
  if(orgName) {
    normalizedOrg = orgName?.toLowerCase() || "default";
  }

  // if (alert > 0) {
  //   return orgIcons[normalizedOrg]?.alert || orgIcons.default.alert;
  // }

  return getSOCIcon(normalizedOrg, soc);
}

export default FindMapIcon;
